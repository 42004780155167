import { keycloakInstance } from "../../../keycloak";

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

export const fetchWrapped = (url, opts) =>
  fetch(url, {
    ...opts,
    headers: headers(),
  })
    .then((resp) => {
      if (resp) {
        if (resp.ok && (resp.status === 200 || resp.status === 204)) {
          console.info("RESP FOUND", resp);
          return { resp };
        } else if (resp.status === 403) {
          window.location.href = "/#/error/";
          return {
            error: { statusCode: 403, statusMessage: "Not authorized" },
          };
        } else if (resp.status === 401) {
          return {
            error: {
              statusCode: resp.status,
              statusMessage: resp.statusText,
            },
          };
        } else if (resp.json) {
          return resp.json().then(({ message }) => {
            const m = {
              error: {
                statusCode: resp.status,
                statusMessage: resp.statusText,
                message,
              },
            };
            console.error("RESP ERROR with message", m);
            return m;
          });
        } else {
          console.error("RESP ERROR", resp);
          return {
            error: { statusCode: resp.status, statusMessage: resp.statusText },
          };
        }
      }
    })
    .catch((error) => {
      console.error("ERROR CATCH", error);
      return {
        error: { statusCode: 503, statusMessage: "Service Unavailable" },
      };
    });

const headers = () => {
  if (keycloakInstance.keycloak.authenticated) {
    try {
      const minValidity = 60;
      const refreshed = keycloakInstance.keycloak.updateToken(minValidity);
      console.log(refreshed ? "Token was refreshed" : "Token is still valid");
    } catch (error) {
      console.error("Failed to refresh token", error);
      keycloakInstance.keycloak.login();
    }
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${keycloakInstance.keycloak.token}`,
    };
  } else if (keycloakInstance.keycloak) {
    keycloakInstance.keycloak.login();
  } else {
    throw new Error("No keycloak instance!");
  }
};
