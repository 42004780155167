import React from "react";
import { Route, Switch } from "react-router-dom";
import { PageEditMember } from "../pages";
import {
  PageMemberDashboardContainer,
  PageCreateMemberContainer,
  PageSearchMemberContainer,
} from "../containers/member";
import { PageResellersContainer } from "../containers/reseller";
import { PageMetadataContainer } from "../containers/metadata";
import { AuthError } from "../common/error";
import { keycloakInstance } from "../keycloak";

const PageEditMemberSecured = (props) => <PageEditMember {...props} />;
export const MainRouts = () => {
  const keycloak = keycloakInstance && keycloakInstance.keycloak;
  const federationId = keycloak && keycloak.realm;
  const baseUrl = `/federation/${federationId}`;
  return (
    <Switch>
      <Route exact path="/" component={PageSearchMemberContainer} />
      <Route exact path={baseUrl} component={PageSearchMemberContainer} />
      <Route
        path={`${baseUrl}/members/create/`}
        component={PageCreateMemberContainer}
      />
      <Route
        path={`${baseUrl}/members/show/:customerId/`}
        component={PageMemberDashboardContainer}
      />
      <Route
        path={`${baseUrl}/members/show/:customerId/:activeTab/`}
        component={PageMemberDashboardContainer}
      />
      <Route
        path={`${baseUrl}/members/edit/:prefixCode/`}
        component={PageEditMemberSecured}
      />

      <Route
        path={`${baseUrl}/resellers/`}
        component={PageResellersContainer}
      />

      <Route path={`${baseUrl}/metadata/`} component={PageMetadataContainer} />
      <Route path={`/error/`} component={AuthError} />
    </Switch>
  );
};
