import { configureStore } from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import * as ReselectTools from "reselect-tools";
import { rootReducer } from "./ducks";
import rootSaga from "./sagas";
import * as selectors from "./ducks/member/selectors";

const sagaMiddleware = createSagaMiddleware();

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({ thunk: false }).concat(logger, sagaMiddleware);

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV === "development",
  preloadedState: {},
});

sagaMiddleware.run(rootSaga);

ReselectTools.getStateWith(() => store.getState());
ReselectTools.registerSelectors(selectors);

export default store;
