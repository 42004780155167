import { fetchWrapped, GET, POST, DELETE } from "../api";
import { keycloakInstance } from "../../../keycloak";

const API_ENDPOINT = "/fedreg/apis/metadataadmin/api";

export const uploadMetadataApi = ({
  federationId,
  formData,
  customerId,
  publishFile,
}) => {
  return fetch(
    `${API_ENDPOINT}/metadata/federation/${federationId}/customer/${customerId}/extended?publish=${publishFile}`,
    {
      method: POST,
      body: formData,
      headers: headers(),
    },
  )
    .then((resp) => {
      if (resp) {
        if (resp.ok && (resp.status === 200 || resp.status === 204)) {
          console.info("RESP FOUND", resp);
          return { resp };
        } else if (resp.status === 403) {
          window.location.href = "/#/error/";
          return {
            error: { statusCode: 403, statusMessage: "Not authorized" },
          };
        } else if (resp.status === 413) {
          return {
            error: { statusCode: 413, statusMessage: "File to large!" },
          };
        } else if (resp.status >= 401 && resp.status < 500) {
          return {
            error: {
              statusCode: resp.status,
              statusMessage: resp.statusText,
            },
          };
        } else if (resp.json) {
          return resp.json().then(({ message }) => {
            const m = {
              error: {
                statusCode: resp.status,
                statusMessage: resp.statusText,
                message,
              },
            };
            console.error("RESP ERROR with message", m);
            return m;
          });
        } else {
          console.error("RESP ERROR", resp);
          return {
            error: { statusCode: resp.status, statusMessage: resp.statusText },
          };
        }
      }
    })
    .catch((error) => {
      console.error("ERROR CATCH", error);
      return {
        error: { statusCode: 503, statusMessage: "Service Unavailable" },
      };
    });
};

export const fetchMemberMetadataListApi = ({ federationId, customerId }) =>
  fetchWrapped(
    `${API_ENDPOINT}/metadata/federation/${federationId}/customer/${customerId}`,
    {
      method: GET,
    },
  );

export const fetchFederationMetadataListApi = ({ federationId }) =>
  fetchWrapped(
    `${API_ENDPOINT}/metadata/federation/${federationId}/forapproval`,
    {
      method: GET,
    },
  );

export const fetchMetadataValidateApi = ({
  federationId,
  customerId,
  entityId,
  approval,
}) =>
  fetchWrapped(
    `${API_ENDPOINT}/metadata/federation/${federationId}/customer/${customerId}/entity/?entity-id=${encodeURIComponent(
      entityId,
    )}&validate=true&approval=${approval}`,
    {
      method: GET,
    },
  );

export const fetchMetadataApi = ({ federationId, entityId }) =>
  fetchWrapped(
    `${API_ENDPOINT}/metadata/federation/${federationId}/forapproval/entity?entity-id=${encodeURIComponent(
      entityId,
    )}`,
    {
      method: GET,
    },
  );
export const updateMetadataApi = ({ federationId, entityId, active }) =>
  fetchWrapped(
    `${API_ENDPOINT}/metadata/federation/${federationId}/entity/active?active=${active}&entity-id=${encodeURIComponent(
      entityId,
    )}`,
    {
      method: POST,
    },
  );
export const deleteMetadataApi = ({ federationId, entityId, approved }) =>
  fetchWrapped(
    `${API_ENDPOINT}/metadata/federation/${federationId}?entity-id=${encodeURIComponent(
      entityId,
    )}&approved=${approved}`,
    {
      method: DELETE,
    },
  );

export const approveMetadataApi = ({
  federationId,
  entityId,
  approve,
  message,
}) =>
  fetchWrapped(
    `${API_ENDPOINT}/metadata/federation/${federationId}/entity/approved?approved=${approve}&entity-id=${encodeURIComponent(
      entityId,
    )}`,
    {
      method: POST,
      body: message,
    },
  );

const headers = () => {
  if (keycloakInstance.keycloak.authenticated) {
    try {
      const minValidity = 60;
      const refreshed = keycloakInstance.keycloak.updateToken(minValidity);
      console.log(refreshed ? "Token was refreshed" : "Token is still valid");
    } catch (error) {
      console.error("Failed to refresh token", error);
      keycloakInstance.keycloak.login();
    }
    return {
      Authorization: `Bearer ${keycloakInstance.keycloak.token}`,
    };
  } else if (keycloakInstance.keycloak) {
    keycloakInstance.keycloak.login();
  } else {
    throw new Error("No keycloak instance!");
  }
};

export const fetchFederationMDCountApi = ({ federationId }) =>
  fetchWrapped(
    `${API_ENDPOINT}/metadata/federation/${federationId}/customers/count`,
    {
      method: GET,
    },
  );
