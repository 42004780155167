import React from "react";
import PropTypes from "prop-types";
import { unifiedDiff } from "difflib";
import { format } from "util";
import { Diff2Html } from "diff2html";
import "diff2html/dist/diff2html.css";

const defaultOptions = {
  originalFileName: "Unknown-File-Name",
  updatedFileName: "Unknown-File-Name",
  inputFormat: "diff",
  outputFormat: "line-by-line",
  showFiles: false,
  matching: "words",
  matchWordsThreshold: 0.25,
  matchingMaxComparisons: 2500,
};

export class MetadataDiff extends React.Component {
  constructor(props) {
    super();

    this.state = {
      file1: props.file1,
      file2: props.file2,
      fileName: props.fileName,
    };
    this.generateDiff = this.generateDiff.bind(this);
  }
  componentDidMount() {
    this.generateDiff();
  }

  generateDiff() {
    defaultOptions.originalFileName = this.state.fileName;
    defaultOptions.updatedFileName = this.state.fileName;
    const str = this.compare({
      past: this.state.file1,
      current: this.state.file2,
      options: defaultOptions,
    });
    const diff2html = Diff2Html.getPrettyHtml(str, defaultOptions);
    this.diffdiv.innerHTML = diff2html;
  }

  compare({ past, current, options }) {
    const pastArray = past.split(/\r|\n|\r\n/);
    const currentArray = current.split(/\r|\n|\r\n/);

    const diffArray = unifiedDiff(pastArray, currentArray, {
      fromfile: options.originalFileName,
      tofile: options.updatedFileName,
    });

    const diffString = format(
      "diff --git %s %s\n%s",
      options.originalFileName,
      options.updatedFileName,
      diffArray.join("\n"),
    );

    return diffString;
  }

  render() {
    // const { diff2html } = this.state;
    return (
      <div ref={(diffdiv) => (this.diffdiv = diffdiv)}>
        {/* <div dangerouslySetInnerHTML={{ __html: diff2html }} /> */}
      </div>
    );
  }
}

MetadataDiff.propTypes = {
  file1: PropTypes.string.isRequired,
  file2: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default MetadataDiff;
